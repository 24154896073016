<template>
    <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotclassifitwins' }">孪生体分类</el-breadcrumb-item>
          <el-breadcrumb-item>编辑分类</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-form-item label="项目名称" prop="">
            <el-input v-model="form.projectName" disabled></el-input>
          </el-form-item>
          <el-form-item label="分类名称" prop="type">
            <el-input v-model="form.type" disabled placeholder="请输入分类名称" show-word-limit maxlength="20"></el-input>
          </el-form-item>
          <!-- 孪生体信息 -->
          <div style="display: flex;justify-content: space-between;align-items: flex-start;margin-top: 40px;">
            <div class="form-title">孪生体信息</div>
            <div>
              <el-button type="danger" size="small" plain :disabled="tableData.length==0" @click="delTwins">清空所选孪生体</el-button>
              <el-button type="primary" size="small" @click="selectTwins">选择孪生体</el-button>
            </div>
          </div>
          <div class="table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="park" label="分类名称"></el-table-column>
              <el-table-column label="孪生体编号">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinId }}
                </template>
              </el-table-column>
              <el-table-column label="孪生体名称">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinName }}
                </template>
              </el-table-column>
              <el-table-column label="绑定设备">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.bindEquipment==0">未绑定</el-tag>
                  <el-tag type="success" v-if="scope.row.bindEquipment==1">已绑定</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间">
                <template slot-scope="scope">
                  {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <div>
                    <span @click="dltTwins(scope.row)" style="color:red;cursor:pointer;">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotclassifitwins')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title="选择孪生体"
        :with-header="false"
        :visible.sync="twinsDrawer"
        direction="rtl"
        size="1100px">
        <div class="drawer-title">选择孪生体</div>
        <div class="drawer-content table-tree">
          <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
            <div class="tree-title">园区</div>
            <el-tree
              :data="parkData"
              node-key="id"
              @node-click="parkClick"
              :props="parkProps"
              :default-checked-keys="[parkCode]"
              highlight-current
              :current-node-key="parkCode"
              ref="parkTree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" height="700" v-loading="tableTwinsLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="park" label="分类名称"></el-table-column>
              <el-table-column label="孪生体编号">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinId }}
                </template>
              </el-table-column>
              <el-table-column label="孪生体名称">
                <template slot-scope="scope">
                  {{ scope.row.digitalTwinName }}
                </template>
              </el-table-column>
              <el-table-column label="绑定设备">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.bindEquipment==0">未绑定</el-tag>
                  <el-tag type="success" v-if="scope.row.bindEquipment==1">已绑定</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="160">
                <template slot-scope="scope">
                  {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="twinsDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="twinsDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </template>
  
  <script>
  import dayjs from 'dayjs';
  export default {
    data() {
      return {
        loading: false,
        twinsDrawer: false,
        treeLoading: false,
        tableTwinsLoading: false,
        staffId: null,
        current: null,
        form: {},
        rules: {
          type:[{ required: true, message: '必填项', trigger: 'blur' }],
        },
        parkProps: {
          children: 'childTree',
          label: 'name'
        },
        parkCode: '',
        parkData: [],
  
        tableData: [],
        tableDataCp: [],
        tableSelectList: []
      }
    },
    methods: {
      dayjs,
      onSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if(this.tableData.length==0) return this.$message.error('请选择孪生体')
            let digitalTwinIds = []
            this.tableData.forEach(v=>{
                digitalTwinIds.push(v.digitalTwinId)
            })
            let form = {
              projectId: this.projectId,
              typeId: this.form.typeId,
              digitalTwinIds
            }
            this.loading = true
            this.$ajax.post('uinoDigitalTwinTypeDistribute', form).then(res => {
              this.loading = false
              this.$message.success('成功')
              this.$router.push('/iotclassifitwins')
            }).catch(err=>{
              this.loading = false
            })
          }
        })
      },
      // 切换园区
      parkClick(data) {
        this.current = data.name
        this.queryTwinsUnderThepark()
      },
      // 清空所选孪生体
      delTwins() {
        this.tableData = []
        this.tableSelectList = []
      },
      // 打开选择孪生体侧滑面板
      selectTwins() {
        setTimeout(()=>{ //高亮选中
          this.$refs.parkTree.setCurrentKey(this.parkData[0].id)
        },200)
        this.queryTwinsUnderThepark()
        this.twinsDrawer = true
      },
      // 勾选孪生体数据
      checkeChange(row) {
        if (row.checked) {
          // 勾选
          this.tableSelectList.push(row)
        } else {
          this.tableSelectList.forEach((item, index) => {
            if (item.id == row.id) {
              this.tableSelectList.splice(index, 1)
            }
          })
        }
      },
      // 确认选择孪生体数据
      twinsDrawerOK() {
        this.tableData = []
        if(this.tableSelectList.length==0) return this.$message.error('请选择孪生体')
        this.tableSelectList.forEach(item=>{
          this.tableData.push(item)
          this.twinsDrawer = false
        })
      },
      // 删除已选择孪生体信息
      dltTwins(row){
        this.tableData.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableData.splice(index, 1)
          }
        })
        this.tableSelectList.forEach((v,k) => {
          if (v.id == row.id) {
            this.tableSelectList.splice(k, 1)
          }
        })
      },
      // 获取孪生体分类
      loadClassifyList() {
        this.treeLoading = true
        this.$ajax.post('iotUinoClassifQuery', {
          companyId: this.companyId,
          staffId: this.staffId,
          projectId: this.projectId
        }).then(res => {
          this.current = res.data[0]
          res.data.forEach(v=>{
            this.parkData.push({id: v, name: v})
          })
          this.treeLoading = false
        }).catch(err=>{
          this.treeLoading = false
        })
      },
      // 获取园区下对应的孪生体数据
      queryTwinsUnderThepark() {
        this.tableTwinsLoading = true
        this.$ajax.post('iotTwinsQuery', {
          companyId: this.companyId,
          staffId: this.staffId,
          projectId: this.projectId,
          park: this.current,
        }).then(res => {
          res.data.forEach(v=>{
            v.checked = false
          })
          this.tableDataCp = res.data
          this.tableTwinsLoading = false
  
          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.id == v.id)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }).catch(err => {
          this.tableTwinsLoading = false
        })
      }
    },
    mounted() {
      if (this.$route.params.row == null) {
              this.$router.push('/iotclassifitwins')
      } else {
        this.form = this.$route.params.row
        this.form.type = this.$route.params.type.type
        this.form.typeId = this.$route.params.type.typeId
        this.companyId = this.form.companyId
        this.staffId = sessionStorage.getItem('id')
        this.projectId = this.form.projectId
        this.loadClassifyList()
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .mains {
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .table-tree {
    display: flex;
    height: ~'calc(100% - 56px)';
    margin-top: 20px;
    .tree {
      width: 300px;
      border-right: 4px solid #f6f7fb;
      margin-right: 20px;
    }
    .table {
      flex: 1;
    }
    .operate {
      &:hover {
        color: #409EFF;
      }
    }
    .custom-tree-node {
      font-size: 14px;
    }
  }
  /deep/.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #409eff;
    color: white;
  }
  </style>